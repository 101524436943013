import React, { useState, useContext, useEffect } from 'react';
import { AccountContext } from './Account';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Link, useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-number-input/input';
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import UserPool from '../UserPool';
import './Login.css';

const Login = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const { authenticate } = useContext(AccountContext);

  const handleLogin = (event) => {
    event.preventDefault();
    authenticate(phoneNumber, password)
      .then((data) => {
        console.log("Logged in!", data);
        navigate("/maestros");
      })
      .catch((err) => {
        console.error("Failed to login", err);
      });

    console.log('Logging in with phone number:', phoneNumber, password);
  };

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const { getSession } = useContext(AccountContext);

  useEffect(() => {
    getSession().then((session) => {
            console.log("Session: ", session);
            setIsLoggedIn(true);
        });
  }, []);

  return (
    <div>
      {!isLoggedIn ? (
        <div className="login-container">
          <h1>Iniciar Sesión Maestro</h1>
          <Form onSubmit={handleLogin} className="login-form">
            <Form.Group controlId="phoneNumber" className="mx-md-4 mx-2">
              <PhoneInput
                placeholder="Número Telefónico"
                country="US"
                value={phoneNumber}
                maxLength={14}
                onChange={setPhoneNumber}
                className="form-control"
              />
            </Form.Group>
            <Form.Group controlId="password" className="mx-md-4 mx-2">
              <Form.Control
                type="password"
                placeholder="Contraseña"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
            <Button type="submit" variant="primary" className="mx-md-4 mx-2">
              Entrar
            </Button>
          </Form>
          <p className="signup-link">
            ¿No tiene una cuenta? <Link to="/signup">Registrar</Link>
          </p>
        </div>
      ) :
      (
        navigate("/maestros")
      )}
    </div>
  );
};

export default Login;
