import React, { useState, useContext, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Link, useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-number-input/input';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import './SignUp.css';
import UserPool from '../UserPool';
import { AccountContext } from './Account';

const SignUp = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [phoneNumberValid, setPhoneNumberValid] = useState(true); // Added state for phone number validation
  const [registrationSuccess, setRegistrationSuccess] = useState(false);


  const handleSignUp = () => {
    // Check if passwords match before signing up
    if (password === confirmPassword) {
      // Add your sign-up logic here
      // Create CognitoUserAttribute objects for first name and last name
      const givenNameAttribute = new CognitoUserAttribute({
        Name: 'given_name', // The name of the attribute in your Cognito user pool
        Value: firstName,
      });

      const familyNameAttribute = new CognitoUserAttribute({
        Name: 'family_name', // The name of the attribute in your Cognito user pool
        Value: lastName,
      });

      const attributeList = [givenNameAttribute, familyNameAttribute]
      UserPool.signUp(phoneNumber, password, attributeList, null, (err, data) => {
        if (err) {
          console.error(err);
        }
        console.log(data)
        setRegistrationSuccess(true);
      })

      console.log('Signing up with:', firstName, lastName, phoneNumber, password);
    } else {
      setPasswordsMatch(false);
    }
  };

  
  const validatePhoneNumber = () => {
    if (isPossiblePhoneNumber(phoneNumber)) {
        console.log(phoneNumber);
      setPhoneNumberValid(true);
    } else {
      setPhoneNumberValid(false);
    }
  };

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const { getSession } = useContext(AccountContext);

  useEffect(() => {
    getSession().then((session) => {
            console.log("Session: ", session);
            setIsLoggedIn(true);
        });
  }, []);

  return (
    <div>
      {!isLoggedIn ? (
        <div className="signup-container">
          <h1>Registrar Maestro</h1>
          <Form className="signup-form">
          <div className="d-flex">
              <Form.Group controlId="firstParentFirstName" className="flex-grow-1 mx-2">
                <Form.Control
                  type="text"
                  placeholder="Primer Nombre"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group controlId="firstParentLastName" className="flex-grow-1 mx-2">
                <Form.Control
                  type="text"
                  placeholder="Apellido"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
              </Form.Group>
            </div>
            <Form.Group controlId="phoneNumber" className="mx-2">
              {/* <Form.Label>Phone Number</Form.Label> */}
              <PhoneInput
                placeholder="Número Telefónico"
                country="US"
                value={phoneNumber}
                maxLength={14}
                onChange={(value) => {
                  if (typeof value === 'string') { // Check if value is a string
                    setPhoneNumber(value);
                  }
                }}
                onBlur={validatePhoneNumber}
                className={`form-control ${phoneNumberValid ? '' : 'is-invalid'}`} // Apply validation class
                required
              />
              {!phoneNumberValid && (
                <div className="invalid-feedback">Ingrese un número válido de 10 dígitos.</div>
              )}
              <small className="text-muted">
                **Favor de ingresar un teléfono que estará PRESENTE DURANTE EL RETIRO.
              </small>
            </Form.Group>
            <Form.Group controlId="password" className="mx-2">
              {/* <Form.Label>Password</Form.Label> */}
              <Form.Control
                type="password"
                placeholder="Ingrese su Contraseña"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="confirmPassword" className="mx-2">
              {/* <Form.Label>Confirm Password</Form.Label> */}
              <Form.Control
                type="password"
                placeholder="Confirme su Contraseña"
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  setPasswordsMatch(true);
                }}
              />
              {!passwordsMatch && (
                <small className="text-danger">Passwords do not match.</small>
              )}
            </Form.Group >
            <Button variant="primary" onClick={handleSignUp} className='mx-2'>
              Registrar
            </Button>
            {registrationSuccess && (
                <div className="alert alert-success" role="alert">
                  Registro exitoso. Puede <Link to="/login">iniciar sesión</Link> ahora.
                </div>
              )
            }
            {!registrationSuccess && (
              <p className="signup-link">
                ¿Ya tiene cuenta? <Link to="/login">Iniciar Sesión</Link>
              </p>
            )}
            
          </Form>
        </div>
    ) :
    (
      navigate("/maestros")
    )}
  </div>
  );
};

export default SignUp;
