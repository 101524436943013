import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function RegistrationConfirmation(props) {
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
        {handleShow}
      {/* <Button variant="primary" onClick={handleShow}>
        Launch static backdrop modal
      </Button> */}

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>{props.postOutcome === '' ? "¡Nos Vemos Pronto!" : "ERROR"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {props.postOutcome === '' ? "Su registración fue exitoso." : "Disculpe las molestias, pero su registro no fue exitoso debido al siguiente motivo: <<<" + props.postOutcome + ">>> Inténte registrar de nuevo. Si el error persiste, comuníquese con video@mieloceanside.org"}
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={() => {
            props.closeModal(false);
            handleClose();
          }}>
            Regresar
          </Button> */}
          <Button variant="primary" onClick={() => {
            props.closeModal(false);
            handleClose();
          }}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default RegistrationConfirmation;