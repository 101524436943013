import './App.css';
import Login from './components/Login.js'
import Header from './components/Header.js'
import Footer from './components/Footer.js'
import SignUp from './components/SignUp.js'
import PasswordReset from './components/PasswordReset.js'
import ChildCareRegistration from './components/Register.js';
import DisplayChildData from './components/DisplayChildList';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Account } from './components/Account';
import Status from './components/Status';
import PrivateRoutes from './components/PrivateRoutes';
import Layout from './components/Layout';

function App() {
  return (
    <Account>
      {/* <Status /> */}
      <div className='App'>
      {/* <Header /> */}
      <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index path="/" element={<ChildCareRegistration />} />
            <Route path="login" element={<Login />} />
            <Route path="signup" element={<SignUp />} />
            {/* <Route path="maestros" element={<PrivateRoutes />}> */}
              <Route exact path="maestros" element={<DisplayChildData />} />
            {/* </Route> */}
          </Route>
        </Routes>
    </Router>
      {/* <DisplayChildData /> */}
      {/* <Footer /> */}
      </div>
    </Account>
  );
}

export default App;
