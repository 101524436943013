import React, { useEffect, useState, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import PhoneInput from 'react-phone-number-input/input';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import './ChildCareRegistration.css';
import palabraMielChildrenImage from './Ninos-Header.png';
import backgroundImage from './HD-Wallpaper-2.jpg';

import { Amplify, API } from 'aws-amplify';
import config from '../aws-exports';
import { Authenticator, translations } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { v5 as uuidv5 } from 'uuid';
import RegistrationConfirmation from './RegistrationConfirmation';

const ChildCareRegistration = () => {
  const [firstParentFirstName, setFirstParentFirstName] = useState('');
  const [firstParentLastName, setFirstParentLastName] = useState('');
  const [firstParentPhoneNumber, setFirstParentPhoneNumber] = useState('');
  const [secondParentFirstName, setSecondParentFirstName] = useState('');
  const [secondParentLastName, setSecondParentLastName] = useState('');
  const [secondParentPhoneNumber, setSecondParentPhoneNumber] = useState('');
  const [churchName, setChurchName] = useState('');
  const [comments, setComments] = useState('');
  const [firstPhoneNumberValid, setFirstPhoneNumberValid] = useState(true); // Added state for phone number validation
  const [secondPhoneNumberValid, setSecondPhoneNumberValid] = useState(true); // Added state for phone number validation
  const [secondParentInput, setSecondParentInput] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [postOutcome, setPostOutcome] = useState('');
  const secondParentFirstNameRef = useRef(null);
  const secondParentLastNameRef = useRef(null);
  const secondParentPhoneNumberRef = useRef(null);
  const currentYear = new Date().getFullYear();

  const [showAdditionalChildFields, setShowAdditionalChildFields] = useState(false);
  const [children, setChildren] = useState([
    {
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      allergies: '',
    },
  ]);

  const handleSecondParentFirstNameChange = (e) => {

    e.target.setCustomValidity('');
    setSecondParentFirstName(e.target.value);
    // console.log(e.target.value);
    // console.log(secondParentLastName);
    // console.log(secondParentPhoneNumber);
    // console.log("firstname validity off");
    if (e.target.value || secondParentLastName || secondParentPhoneNumber !== '') {
      setSecondParentInput(true);
    }
    else{
      setSecondParentInput(false);
      if (secondParentLastNameRef.current && secondParentPhoneNumberRef.current) {
        secondParentLastNameRef.current.setCustomValidity('');
        secondParentPhoneNumberRef.current.setCustomValidity('');
        // console.log("phoneNumber off")
        // console.log("lastName off")
      };
    }
  };

  const handleSecondParentLastNameChange = (e) => {
    // console.log(e.target.value);
    // console.log(secondParentLastName);
    // console.log(secondParentPhoneNumber);
    // console.log("lastname validity off");
    e.target.setCustomValidity('');
    setSecondParentLastName(e.target.value);
    if (e.target.value || secondParentFirstName || secondParentPhoneNumber !== '') {
      setSecondParentInput(true);
    }
    else{
      setSecondParentInput(false);
      if (secondParentFirstNameRef.current && secondParentPhoneNumberRef.current) {
        secondParentFirstNameRef.current.setCustomValidity('');
        secondParentPhoneNumberRef.current.setCustomValidity('');
        // console.log("firstName off")
        // console.log("phonumber off")
      };
    }
  };

  const handleSecondParentPhoneNumberChange = (value) => {
    // console.log(value);
    // console.log(secondParentLastName);
    // console.log(secondParentPhoneNumber);
    // console.log("phonenumber validity off");
    if (typeof value === 'string' || value === undefined) {
      setSecondParentPhoneNumber(value);
      if (value || secondParentFirstName || secondParentLastName) {
        setSecondParentInput(true);
      }
      else{
        setSecondParentInput(false);
        if (secondParentFirstNameRef.current && secondParentLastNameRef.current) {
          secondParentFirstNameRef.current.setCustomValidity('');
          secondParentLastNameRef.current.setCustomValidity('');
          // console.log("firstName off")
          // console.log("lastName off")
        };
      }
    }
  };

  const handleAddChild = () => {
    if (children.length < 6) {
      setChildren([...children, { firstName: '', lastName: '', dateOfBirth: '', allergies: '' }]);
      setShowAdditionalChildFields(true);
    }
  };

  const handleRemoveChild = () => {
    if (children.length > 1) {
      const updatedChildren = children.slice(0, -1); // Remove the last child
      setChildren(updatedChildren);
      setShowAdditionalChildFields(updatedChildren.length > 1);
    }
  };

  const handleChildChange = (index, field, value) => {
    const updatedChildren = [...children];
    updatedChildren[index][field] = value;
    setChildren(updatedChildren);
  };

  const validateFirstPhoneNumber = () => {
    if (firstParentPhoneNumber === undefined || firstParentPhoneNumber === '' || isPossiblePhoneNumber(firstParentPhoneNumber)) {
        setFirstPhoneNumberValid(true);
    } else {
        setFirstPhoneNumberValid(false);
    }
  };

  const validateSecondPhoneNumber = () => {
    if (secondParentPhoneNumber === undefined || secondParentPhoneNumber === '' || isPossiblePhoneNumber(secondParentPhoneNumber)) {
        setSecondPhoneNumberValid(true);
    } else {
      setSecondPhoneNumberValid(false);
    }
  };

  const churchOptions = [
    'Oceanside',
    'Bakersfield',
    'Beaumont',
    'Carson City',
    'Concord',
    'Corona',
    'El Cajon',
    'El Monte',
    'Escondido',
    'Fresno',
    'Huntington',
    'Irrigon',
    'Lancaster/Palmdale',
    'Las Vegas',
    'Lompoc',
    'Long Beach',
    'Los Angeles',
    'Los Angeles Sur',
    'Merced',
    'Monte Bello',
    'Murrieta',
    'Oceanside',
    'Okanogan',
    'Ontario',
    'Oxnard',
    'Pasadena',
    'Perris',
    'Phoenix',
    'Pomona',
    'Portland',
    'Reno',
    'Richmond',
    'Sacramento',
    'Salinas',
    'Salt Lake City',
    'San Bernardino',
    'San Diego',
    'San Jose',
    'Santa Ana',
    'Santa Maria',
    'Seattle',
    'St. George',
    'Stockton',
    'Tacoma',
    'Tigard',
    'Tucson',
    'Valle de San Gabriel',
    'Valle de San Fernando',
    'Vancouver',
    'Victorville',
    'Yuma',
    'Otro...'
  ];

  const updateModalShow = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  const closeModal = (modalShow) => {
    setShowModal(modalShow);
    setPostOutcome('');
    handleFormReset();
  };

  const handleFormReset = () => {
    setFirstParentFirstName("");
    setFirstParentLastName("");
    setFirstParentPhoneNumber("");
    setSecondParentFirstName("");
    setSecondParentLastName("");
    setSecondParentPhoneNumber("");
    setChurchName("");
    setComments("");
    setFirstPhoneNumberValid(true); // Added state for phone number validation
    setSecondPhoneNumberValid(true); // Added state for phone number validation
    setSecondParentInput(false);

    setShowAdditionalChildFields(false);
    setChildren([
      {
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        allergies: '',
      },
    ]);
  };

  const handleRegistrationSubmit = (event) => {
    event.preventDefault();

    // <RegistrationConfirmation/>

    if (firstPhoneNumberValid === true && secondPhoneNumberValid === true && firstParentFirstName.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase() + firstParentLastName.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase() + firstParentPhoneNumber.trim().toLowerCase() !== secondParentFirstName.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase() + secondParentLastName.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase() + secondParentPhoneNumber.trim().toLowerCase()){
      // Time of registration.
      let registrationTime = new Date();
      // console.log(registrationTime.toLocaleString());

      //UUID namespace used to create a UUID. A "seed" of sorts.
      const MY_NAMESPACE = 'd32b9c92-9af2-4853-93b9-640109156b5b';

      // Creating first parent's UUID.
      let firstParentUUID = uuidv5(firstParentFirstName + firstParentLastName + firstParentPhoneNumber, MY_NAMESPACE);
      // Creating array for parent's UUIDs and adding the first one. 
      const parentUUIDs = [firstParentUUID];
      // Checking to see if user input information for 2nd parent. If so, create UUID and push to parentUUIDs array.
      if (secondParentPhoneNumber !== '' && secondParentPhoneNumber !== undefined && secondParentLastName !== '' && secondParentLastName !== undefined) {
        let secondParentUUID = uuidv5(secondParentFirstName + secondParentLastName + secondParentPhoneNumber, MY_NAMESPACE);
        parentUUIDs.push(secondParentUUID);
      }

      const childrenUUIDs = []; //array for the UUIDs of the children.

      // This will iterate through every children submitted. 
      {children.map((child, index) => (
        // Pushing UUID to childrenUUIDs array.
        childrenUUIDs.push(uuidv5(child.firstName + child.lastName + child.dateOfBirth, MY_NAMESPACE)),
        // console.log(childrenUUIDs[index]),
        // Make the API call for each child
        API.post('childcareUsers', '/childcareusers/children', {
          body: {
            UUID: childrenUUIDs[index],
            registrationDatetime: registrationTime,
            FirstName: child.firstName,
            LastName: child.lastName,
            DOB: child.dateOfBirth,
            Allergies: child.allergies,
            firstCheckin: false,
            secondCheckin: false,
            thirdCheckin: false,
            parentsUUIDs: parentUUIDs,
            registeredCurrentYear: currentYear,
          },
        }).catch((error) => {
        console.log("ERROR! REGISTRATION FORM NOT SUBMITTED:\n" + error);
        setPostOutcome(error);
        })
        
        // Rest of your JSX for displaying child information
      ))}

      API.post('childcareUsers', '/childcareusers/parents', {
        body: {
          UUID: parentUUIDs[0], // You should replace 'UUID' with the actual key for the UUID in your data
          registrationDatetime: registrationTime,
          FirstName: firstParentFirstName,
          LastName: firstParentLastName,
          PhoneNumber: firstParentPhoneNumber,
          ChurchName: churchName,
          Comments: comments,
          ChildrensUUIDs: childrenUUIDs,
        },
      }).catch((error) => {
        console.log("ERROR! REGISTRATION FORM NOT SUBMITTED:\n" + error);
        setPostOutcome(error);
      })

      
      if (secondParentPhoneNumber !== '' && secondParentPhoneNumber !== undefined && secondParentLastName !== '' && secondParentLastName !== undefined){
        API.post('childcareUsers', '/childcareusers/parents', {
          body: {
            UUID: parentUUIDs[1], // You should replace 'UUID' with the actual key for the UUID in your data
            registrationDatetime: registrationTime,
            FirstName: secondParentFirstName,
            LastName: secondParentLastName,
            PhoneNumber: secondParentPhoneNumber,
            ChurchName: churchName,
            Comments: comments,
            ChildrensUUIDs: childrenUUIDs,
          },
        }).catch((error) => {
        console.log("ERROR! REGISTRATION FORM NOT SUBMITTED:\n" + error);
        setPostOutcome(error);
        })
      }
      // console.log("Registration Success!")
      setShowModal(true);
    }
    // window.location.reload();
  };

  // useEffect(() => {
  //   API.get('childcareUsers', '/childcareusers/children').then(childrenRes => console.log(childrenRes));
  // },[])

  return (
    <div className="child-care-registration-container" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <img src={palabraMielChildrenImage} alt="Image of Palabra Miel children"  style={{ 
          backgroundSize: 'cover',
          // height: '100%', // Set the desired height
          width: '50%',   // Make it as wide as its container
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center', 
          overflowX: 'hidden',
        }}/>
        <h1 style={{ textDecoration: 'underline' }}>Registración - Área de Niños</h1>
        <hr style={{ border: '2px solid #000', margin: '20px 0' }} />
      <Form className="child-care-registration-form" onSubmit={handleRegistrationSubmit}>
      {/* <Form className="child-care-registration-form" onSubmit={updateModalShow}> */}
        {/* Parent Information */}
        <h2>Información de los Padres</h2>
        <h5 style={{ textDecoration: 'underline' }}>Información del 1er Padre</h5>
        <div className="d-flex">
          <Form.Group controlId="firstParentFirstName" className="flex-grow-1 mx-2">
            <Form.Control
              type="text"
              placeholder="Primer Nombre"
              value={firstParentFirstName}
              onChange={(e) => {
                setFirstParentFirstName(e.target.value);
                e.target.setCustomValidity('');
              }}
              onInvalid={(e) => e.target.setCustomValidity("Favor de ingresar nombre")}
              required
            />
          </Form.Group>
          <Form.Group controlId="firstParentLastName" className="flex-grow-1 mx-2">
            <Form.Control
              type="text"
              placeholder="Apellido"
              value={firstParentLastName}
              onChange={(e) => {
                e.target.setCustomValidity('');
                setFirstParentLastName(e.target.value);
              }}
              onInvalid={(e) => e.target.setCustomValidity("Favor de ingresar apellido")}
              required
            />
          </Form.Group>
        </div>
        <Form.Group controlId="firstParentPhoneNumber" className="mx-2">
          {/* <Form.Label>Phone Number</Form.Label> */}
          <PhoneInput
            placeholder="Número Telefónico"
            country="US"
            value={firstParentPhoneNumber}
            maxLength={14}
            onChange={(value) => {
              if (typeof value === 'string' || value === undefined) { // Check if value is a string
                setFirstParentPhoneNumber(value);
              };
            }}
            onBlur={validateFirstPhoneNumber}
            className={`form-control ${firstPhoneNumberValid ? '' : 'is-invalid'}`} // Apply validation class
            onInvalid={(e) => e.target.setCustomValidity("Favor de ingresar número telefónico")}
            onInput={(e) => e.target.setCustomValidity('')}
            required
          />
          {!firstPhoneNumberValid && (
            <div className="invalid-feedback">Ingrese un número válido de 10 dígitos.</div>
          )}
          <small className="text-muted">
            **Favor de ingresar un teléfono que estará PRESENTE DURANTE EL RETIRO.
          </small>
        </Form.Group>
        <h5 style={{ textDecoration: 'underline' }}>Información del 2do Padre (Opcional)</h5>
        <div className="d-flex">
          <Form.Group controlId="secondParentFirstName" className="flex-grow-1 mx-2">
            <Form.Control
              ref={secondParentFirstNameRef}
              type="text"
              placeholder="Primer Nombre"
              value={secondParentFirstName}
              // onChange={(e) => setSecondParentFirstName(e.target.value)}
              onChange={(e) => {
                e.target.setCustomValidity('');
                handleSecondParentFirstNameChange(e);
              }}
              onInvalid={(e) => {
              // console.log("firstname validity on");
              e.target.setCustomValidity("Favor de ingresar nombre");
              }}
              required={secondParentInput}
            />
          </Form.Group>
          <Form.Group controlId="secondParentLastName" className="flex-grow-1 mx-2">
            <Form.Control
              ref={secondParentLastNameRef}
              type="text"
              placeholder="Apellido"
              value={secondParentLastName}
              // onChange={(e) => setSecondParentLastName(e.target.value)}
              onChange={(e) => {
                e.target.setCustomValidity('');
                handleSecondParentLastNameChange(e);
              }}
              onInvalid={(e) => {
              // console.log("lastname validity on");
              e.target.setCustomValidity("Favor de ingresar nombre");
              }}
              required={secondParentInput}
            />
          </Form.Group>
        </div>
        <Form.Group controlId="secondParentPhoneNumber" className="mx-2">
          {/* <Form.Label>Phone Number</Form.Label> */}
          <PhoneInput
            ref={secondParentPhoneNumberRef}
            placeholder="Número Telefónico"
            country="US"
            value={secondParentPhoneNumber}
            maxLength={14}
            // onChange={(value) => {
            //   if (typeof value === 'string') { // Check if value is a string
            //     setSecondParentPhoneNumber(value);
            //   }
            // }}
            onChange={handleSecondParentPhoneNumberChange}
            onBlur={validateSecondPhoneNumber}
            className={`form-control ${secondPhoneNumberValid ? '' : 'is-invalid'}`} // Apply validation class
            onInvalid={(e) => {
              // console.log("phone# validity on");
              e.target.setCustomValidity("Favor de ingresar número telefónico");
              }}
            onInput={(e) => {
              // console.log("phone# validity off");
              e.target.setCustomValidity('');
              }}
            required={secondParentInput}
          />
          {!secondPhoneNumberValid && (
            <div className="invalid-feedback">Ingrese un número válido de 10 dígitos.</div>
          )}
          <small className="text-muted">
            **Favor de ingresar un teléfono que estará PRESENTE DURANTE EL RETIRO.
          </small>
        </Form.Group>
        <Form.Group controlId="churchName" className="mx-2">
          <Form.Control
            as="select"
            value={churchName}
            onChange={(e) => {
              e.target.setCustomValidity('');
              setChurchName(e.target.value);
            }}
            onInvalid={(e) => e.target.setCustomValidity("Favor de seleccionar su iglesia")}
            required
          >
            <option value="">Seleccione su Iglesia...</option>
            {churchOptions.map((option, index) => (
                <option key={index} value={option}>
                {option}
                </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="comments" className="mx-2">
            <Form.Control
                as="textarea"
                rows={4}
                placeholder="¿Algo que desea comentar al maestro? (Opcional)"
                value={comments}
                onChange={(e) => setComments(e.target.value)}
            />
        </Form.Group>
        <hr style={{ border: '2px solid #000', margin: '20px 0' }} />
        {/* Child Information */}
        <h2>Información de los Niños</h2>
        {/* {showAdditionalChildFields && (
          <div> */}
            {children.map((child, index) => (
                <div key={index}>
                    <h5 style={{ textDecoration: 'underline' }}>Información del Niño Num.{index + 1}</h5>
                    <h6><span style={{ textDecoration: 'underline' }} className="text-danger">***IMPORTANTE***</span> Cuidado de niños solamente para edades entre 1 a 10 años.</h6>
                    <div className="d-flex">
                        <Form.Group controlId={`childFirstName-${index}`} className="flex-grow-1 mx-2">
                            <Form.Control
                            type="text"
                            placeholder={`Primer Nombre`}
                            value={child.firstName}
                            onChange={(e) => {
                              e.target.setCustomValidity('');
                              handleChildChange(index, 'firstName', e.target.value);
                            }}
                            onInvalid={(e) => e.target.setCustomValidity("Favor de ingresar nombre")}
                            required
                            />
                        </Form.Group>
                        <Form.Group controlId={`childLastName-${index}`} className="flex-grow-1 mx-2">
                            <Form.Control
                            type="text"
                            placeholder={`Apellido`}
                            value={child.lastName}
                            onChange={(e) => {
                              e.target.setCustomValidity('');
                              handleChildChange(index, 'lastName', e.target.value);
                            }}
                            onInvalid={(e) => e.target.setCustomValidity("Favor de ingresar apellido")}
                            required
                            />
                        </Form.Group>
                    </div>
                    <div className="d-flex">
                        <Form.Group controlId={`childDateOfBirth-${index}`} className="flex-grow-1 mx-2" style={{ width: '50%' }}>
                        <Form.Control
                            type="date"
                            placeholder={'Fecha de Nacimiento'}
                            value={child.dateOfBirth}
                            onChange={(e) => {
                              e.target.setCustomValidity('');
                              handleChildChange(index, 'dateOfBirth', e.target.value);
                            }}
                            onInvalid={(e) => e.target.setCustomValidity("Favor de ingresar fecha de nacimiento")}
                            required
                        />
                        <label className="date-label ">Fecha de Nacimiento</label>
                        </Form.Group>
                        <Form.Group controlId={`childAllergies-${index}`} className="flex-grow-1 mx-2" style={{ width: '50%' }}>
                        <Form.Control
                            type="text"
                            placeholder={`Alergias o Necesidades Especiales (Opcional)`}
                            value={child.allergies}
                            onChange={(e) => handleChildChange(index, 'allergies', e.target.value)}
                        />
                        <label className="date-label ">Favor de ingresar comentario SOLAMENTE si hay alergia o necesidad especial. Si no hay nada, deje vacío. NO ingrese palabras como "no" "n/a" "ninguna".</label>
                        </Form.Group>
                    </div>

                        {index < children.length - 1 && <hr style={{ borderTop: '2px solid #000' }}/>} {/* Horizontal line */}
                </div>
            ))}
          {/* </div>
        )} */}
        <div className="d-flex justify-content-between align-items-center">
          {children.length < 6 && (
            <Button
              variant="success" // Green color
              onClick={handleAddChild}
              className="mx-2 ml-auto" // Added margin for spacing
            >
              Agregar Otro Niño
            </Button>
          )}

          {showAdditionalChildFields && (
            <Button variant="danger" onClick={handleRemoveChild} className="mx-2">
              Remover Último Niño
            </Button>
          )}
        </div>

        {/* Submit Button */}
        <Button type="submit" variant="primary" className="mx-2 w-100">
          Enviar
        </Button>
        
        
        {showModal && 
        (
          <RegistrationConfirmation 
            closeModal={closeModal}
            postOutcome={postOutcome}
          />
        )}

      </Form>
    </div>
  );
};

export default ChildCareRegistration;
